/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 10,
    borderRadius: 4,
    margin: '0 20px',
    minHeight: 55,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 20,
    backgroundColor: 'rgba(228, 17, 57, 0.7)',
  },
  text: {
    color: 'white',
    fontSize: 16,
  },
}));

const BottomAdText = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return window.innerWidth >= 560 ? (
    <div className={classes.root} style={{ width: props.width, left: props.left }}>
      <p className={classes.text}>{t('translation:commonPages:promo')}</p>
    </div>
  ) : null;
};

export default BottomAdText;
