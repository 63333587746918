import { Box, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(3),
    fontSize: 16,

    '& a': {
      color: '#2E3542',
    },
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography component="div">
        <Box mb={2} fontSize={20} fontWeight={600}>
          {t('translation:privacy:title')}
        </Box>
      </Typography>

      <Paper elevation={1} className={classes.paper}>
        <h1>allPWS.com Global Privacy Policy</h1>
        <p>Last updated: June 24, 2021</p>
        <p>
          TimeSoft, Inc. and its affiliates (collectively “TimeSoft”, “we” and “us”) respect your privacy. We offer
          services that enable private persons and businesses (hereinafter called “Customers”, or “Users”) to store
          their own data in an extremly secure and reliable way, and deliver these data to a designated addressees on
          the time as instructed by the customers.
        </p>

        <p>
          This Global Privacy Policy describes the types of <a href="#personal-data-definition">Personal Data</a> we
          could collect through our secure storage services (“Services”) and via our online presence, which include our
          main website at{' '}
          <a href="https://allPWD.com" target="_blank" rel="noreferrer">
            allPWD.com
          </a>
          , as well as services and websites that we enable Internet users to access, such as{' '}
          <a href="https://savetofuture.com" target="_blank" rel="noreferrer">
            savetofuture.com
          </a>
          ,{' '}
          <a href="https://opentofuture.com" target="_blank" rel="noreferrer">
            opentofuture.com
          </a>{' '}
          (collectively, our “Sites”). This policy also describes how we use Personal Data, with whom we share it, your
          rights and choices, and how you can contact us about our privacy practices. This policy does not apply to
          third-party websites, products, or services, even if they link to our Services or Sites, and you should
          consider the privacy practices of those third-parties carefully.
        </p>

        <p>
          This Privacy Policy is provided in a layered format. Select below an article title to move to a specific
          section.
        </p>

        <ul>
          <li>
            <a href="#overview">Overview</a>
          </li>
          <li>
            <a href="#personal-data-definition">Personal Data We Collect</a>
          </li>
          <li>
            <a href="#how-we-use-personal-data">How We Use Personal Data</a>
          </li>
          <li>
            <a href="#how-we-disclose-personal-data">How We Disclose Personal Data</a>
          </li>
          <li>
            <a href="#your-rights-and-choices">Your Rights and Choices</a>
          </li>
          <li>
            <a href="#security-and-retention">Security and Retention</a>
          </li>
          <li>
            <a href="#international-data-transfers">International Data Transfers</a>
          </li>
          <li>
            <a href="#use-by-minors">Use by Minors</a>
          </li>
          <li>
            <a href="#updates-to-this-privacy-policy-and-notifications">Updates To this Privacy Policy</a>
          </li>
          <li>
            <a href="#links-to-other-websites">Links To Other Websites</a>
          </li>
          <li>
            <a href="#jurisdiction-specific-provisions">Jurisdiction-specific Provisions</a>
          </li>
          <li>
            <a href="#contact-us">Contact Us</a>
          </li>
        </ul>

        <h3 id="overview">1. Overview</h3>

        <p>
          TimeSoft obtains Personal Data about you from various sources to provide our Services and to manage our Sites.
          “You” may be a visitor to one of our websites, a user of one or more of our Services (“User” or “allPWD
          User”), or a customer of a User (“Customer”).
        </p>

        <dl className="collapsable">
          <dt>
            <strong id="overview-learn-more">Learn more</strong>
          </dt>
          <dd>
            <p>
              <br />
            </p>
            <p>
              <strong>a. Site visitors.</strong>
            </p>
            <p>
              If you visit or use our Sites, we may collect Personal Data. For example, we collect Personal Data that
              you submit to us via online forms and surveys, and when you contact us by email.
            </p>
            <p>
              <strong>
                b. Payment processing Services (TimeSoft as a client to a 3rd party data processor services).
              </strong>
            </p>
            <p>
              As a processor of payment transactions, we may collect, use and disclose Personal Data about Customers
              when we interacting with a 3rd party payments processing service providers. Our 3rd party payments
              processing providers are responsible for making sure that the Customer’s privacy rights are respected,
              including ensuring appropriate disclosures about third party data collection and use. To the extent that
              we are acting as a TimeSoft User’s data processor, we will process Personal Data in accordance with the
              terms of our agreement with the our payment processing providers. If you are a Customer and would like to
              obtain more information about how a TimeSoft patments processing providers process your Personal Data in
              the context of payment transactions, please contact the our payment processing providers directly.
            </p>
            <p>
              <strong>
                c. Fraud prevention activities and activities for offering a compliant and secure platform.
              </strong>
            </p>
            <p>
              The collection and use of Personal Data is critical in helping us to ensure that our Services and Sites
              are safe, secure and compliant. In the context of fraud monitoring, prevention and detection services, we
              may monitor insights and patterns of our Services usage and other online signals to reduce the risk of
              fraud, unproper usage of Services, and other harmful activity.
            </p>
          </dd>
        </dl>

        <h3 id="personal-data-definition">2. Personal Data We Collect</h3>

        <p>
          <strong>a. Personal Data that we collect about you.</strong>
        </p>
        <p>
          <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
          The Personal Data that you provide directly to us through our TimeSoft and Services will be apparent from the
          context in which you provide the data. In particular:
        </p>

        <ul>
          <li>
            When you register for a Service account we collect your full name, email address, and account log-in
            credentials.
          </li>
          <li>
            When you fill-in our online form to contact our sales team, we collect your full name, work email, country,
            and anything else you tell us about your project, needs and timeline.
          </li>
        </ul>

        <p>
          When you respond to TimeSoft emails or surveys we collect your email address, name and any other information
          you choose to include in the body of your email or responses. If you contact us by phone, we will collect the
          phone number you use to call TimeSoft. If you contact us by phone as a User of one of our Services, we may
          collect additional information in order to verify your identity and provide requested support.
        </p>

        <p>
          When we conduct fraud monitoring, prevention, detection, and other compliance activities or provide such
          services to our Users, we will receive Personal Data from you (and your device) and about you through our
          Service and could be also from our business partners, financial service providers, identity verification
          services if scuh is required, and publicly available sources (e.g., name, address, phone number, country), to
          prevent fraud. Our fraud monitoring, detection and prevention services may collect Personal Data about you and
          use technology to help us assess the risk associated with an attempted transaction by you with a TimeSoft
          Services.
        </p>

        <p>
          You may also choose to submit information to us via other methods, including: (i) in response to marketing or
          other communications, (ii) through social media or online forums, (iii) through participation in an offer,
          program or promotion, (iv) in connection with an actual or potential business relationship with us, or (v) by
          giving us your business card or contact details at trade shows or other events.
        </p>

        <p>
          <strong>b. Information that we collect automatically on our Sites.</strong>
        </p>

        <p>
          Our Sites use cookies and other technologies to function effectively. These technologies record information
          about your use of our Sites, including:
        </p>

        <ul>
          <li>
            <strong>Browser and device data</strong>, such as IP address, device type, operating system and Internet
            browser type, screen resolution, operating system name and version, device manufacturer and model, language,
            plug-ins, add-ons and the language version of the Sites you are visiting;
          </li>
          <li>
            <strong>Usage data</strong>, such as time spent on the Sites, pages visited, links clicked, language
            preferences, and the pages that led or referred you to our Sites.
          </li>
        </ul>

        <p>
          We also can collect information about your online activities on websites and connected devices over time and
          across third-party websites, devices, apps and other online features and services. We use Google Analytics on
          our Sites to help us analyze Your use of our Sites and diagnose technical issues.
        </p>
        <p>
          To learn more about the cookies that may be served through our Sites and how You can control our use of
          cookies and third-party analytics, please see our{' '}
          <a href="https://savetofuture.com/cookie_policy.html" target="_blank" rel="noreferrer">
            Cookie Policy
          </a>
          .
        </p>

        <h3 id="how-we-use-personal-data">3. How We Use Personal Data</h3>

        <p>
          <strong>a. Our products and services.</strong>
        </p>
        <p>
          We rely upon a number of legal grounds to ensure that our use of your Personal Data is compliant with
          applicable law. We use Personal Data to facilitate the business relationships we have with our Users, to
          comply with our financial regulatory and other legal obligations, and to pursue our legitimate business
          interests. We also use Personal Data to secure store your data and for transactions for providing data to
          addressees designated by you.
        </p>

        <dl className="collapsable">
          <dt>
            <strong id="personal-data-learn-more">Learn more</strong>
          </dt>
          <dd>
            <p>
              <br />
            </p>
            <p>
              <strong>Contractual and pre-contractual business relationships.</strong> We use Personal Data for the
              purpose of entering into business relationships with prospective TimeSoft Users, and to perform the
              contractual obligations under the contracts that we have with TimeSoft Users. Activities that we conduct
              in this context include:
            </p>

            <ul>
              <li>
                Creation and management of Services accounts and Services account credentials, including the evaluation
                of applications to commence or expand the use of our Services;
              </li>
              <li>Creation and management of secure stored data;</li>
              <li>Accounting, auditing, and billing activities; and</li>
              <li>
                Processing of payments through our payment processing providers, communications regarding such payments,
                and related customer services.
              </li>
            </ul>

            <p>
              <strong>Legal and regulatory compliance.</strong> We use Personal Data in order to comply with fraud
              monitoring, prevention and detection obligations, laws associated with the identification and reporting of
              illegal and illicit activity. For example, we may be required to record and verify a User’s identity for
              the purpose of compliance with legislation intended to prevent money laundering and financial crimes.
              These obligations are imposed on us by the operation of law, industry standards, and by our financial
              partners, and may require us to report our compliance to third parties, and to submit to third party
              verification audits.
            </p>

            <p>
              <strong>Legitimate business interests.</strong> We rely on our legitimate business interests to process
              Personal Data about you. The following list sets out the business purposes that we have identified as
              legitimate. In determining the content of this list, we balanced our interests against the legitimate
              interests and rights of the individuals whose Personal Data we process. We:
            </p>

            <ul>
              <li>Monitor, prevent and detect fraud and unauthorized payment transactions;</li>
              <li>Mitigate financial loss, claims, liabilities or other harm to Users and TimeSoft;</li>
              <li>Respond to inquiries, send service notices and provide customer support;</li>
              <li>
                Promote, analyze, modify and improve our products, systems, and tools, and develop new products and
                services;
              </li>
              <li>
                Manage, operate and improve the performance of our Sites and Services by understanding their
                effectiveness and optimizing our digital assets;
              </li>
              <li>Analyze and advertise our products and services;</li>
              <li>
                Conduct aggregate analysis and develop business intelligence that enable us to operate, protect, make
                informed decisions, and report on the performance of, our business;
              </li>
              <li>
                Share Personal Data with third party service providers that provide services for us and requre such
                data, or provide provide services on our behalf and business partners which help us operate and improve
                our business;
              </li>
              <li>Ensure network and information security throughout TimeSoft and our Services; and</li>
              <li>Transmit Personal Data within our affiliates for internal administrative purposes.</li>
            </ul>

            <p>
              <strong>
                Payment transactions and related services (TimeSoft as a client to our payments processor partners).
              </strong>
              As a client of our 3rd party patment processor partners, we use Personal Data of our Customers to process
              online payment transactions through our payment processor partners (a third-parties to TimeSoft). All such
              use is pursuant to the terms of our business relationships with our partner patment processors.
            </p>
            <p>
              If we need to use your Personal Data in other ways, we will provide specific notice at the time of
              collection and obtain your consent where required by applicable law.
            </p>
          </dd>
        </dl>
        <p>
          <strong>b. Marketing and events-related communications.</strong>
        </p>
        <p>
          We may send you marketing communications email about TimeSoft products and services, invite you to participate
          in our events or surveys, or otherwise communicate with you for marketing purposes, provided that we do so in
          accordance with the consent requirements that are imposed by applicable law. For example, when we collect your
          business contact details through our participation at trade shows or other events, we may use the information
          to follow-up with you regarding an event, send you information that you have requested on our products and
          services and, with your permission, include you on our marketing information campaigns.
        </p>

        <p>
          <strong>c. Advertising.</strong>
        </p>
        <p>
          When you visit our Sites, we (and our service providers) may use Personal Data collected from you and your
          device to target advertisements for TimeSoft Services to you on our Sites and other sites you visit
          (“interest-based advertising”), where allowed by applicable law. For example, when you visit our Site, we will
          use cookies to identify your device and direct ads for our Services to you. You have choices and control over
          our cookies (or similar technologies) we use to advertise to you. Please see our{' '}
          <a href="https://savetofuture.com/cookie_policy.html" target="_blank" rel="noreferrer">
            Cookie Policy
          </a>{' '}
          for more information. At present, there is no industry standard for recognizing Do Not Track browser signals,
          so we do not respond to them.
        </p>

        <p>
          We do not use, share, rent or sell the Personal Data of our Customers for interest-based advertising. We do
          not sell or rent the Personal Data of our Customers or our Site visitors.
        </p>

        <h3 id="how-we-disclose-personal-data">4. How We Disclose Personal Data.</h3>
        <p>
          TimeSoft does not sell or rent Personal Data to marketers or unaffiliated third parties. We share your
          Personal Data with trusted entities, as outlined below.
        </p>

        <p>
          <strong>a. TimeSoft.</strong> We share Personal Data with other TimeSoft entities (should any such exist) in
          order to provide our Services and for internal administration purposes.
        </p>
        <p>
          <strong>b. Service providers.</strong> We share Personal Data with a limited number of our service providers.
          We have service providers that provide services on our behalf, such as data storage, data backups, website
          hosting, data analysis, information technology and related infrastructure, customer service, email delivery,
          and auditing services. These service providers may need to access Personal Data to perform their services. We
          authorize such service providers to use or disclose the Personal Data only as necessary to perform services on
          our behalf or comply with legal requirements. We require such service providers to contractually commit to
          protect the security and confidentiality of Personal Data they process on our behalf. Our service providers
          are predominantly located in the European Union and the United States of America.
        </p>
        <p>
          <strong>c. Business partners.</strong> We share Personal Data with third party business partners when this is
          necessary to provide our Services to our Users. Examples of third parties to whom we may disclose Personal
          Data for this purpose are banks and payment method providers (such as credit card networks) when we provide
          payment processing services
        </p>

        <p>
          <strong>d. Our Users and third parties authorized by our Users.</strong> We share Personal Data with Users as
          necessary to maintain a User account and provide the Services. We share data with parties directly authorized
          by a User to receive Personal Data,
        </p>

        <p>
          <strong>e. Corporate transactions.</strong> In the event that we enter into, or intend to enter into, a
          transaction that alters the structure of our business, such as a reorganization, merger, sale, joint venture,
          assignment, transfer, change of control, or other disposition of all or any portion of our business, assets or
          stock, we may share Personal Data with third parties in connection with such transaction. Any other entity
          which buys us or part of our business will have the right to continue to use your Personal Data, but only in
          the manner set out in this Privacy Policy unless you agree otherwise.
        </p>

        <p>
          <strong>f. Compliance and harm prevention.</strong> We share Personal Data as we believe necessary: (i) to
          comply with applicable law, or payment method rules; (ii) to enforce our contractual rights; (iii) to protect
          the rights, privacy, safety and property of TimeSoft, you or others; and (iv) to respond to requests from
          courts, law enforcement agencies, regulatory agencies, and other public and government authorities, which may
          include authorities outside your country of residence.
        </p>

        <h3 id="your-rights-and-choices">5. Your Rights and Choices.</h3>

        <p>You have choices regarding our use and disclosure of your Personal Data:</p>
        <p>
          <strong>a. Opting out of receiving electronic communications from us.</strong> If you no longer want to
          receive marketing-related emails from us, you may opt-out via the unsubscribe link included in such emails. We
          will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt-out of
          receiving marketing-related emails from us, we may still send you important administrative messages that are
          required to provide you with our Services.
        </p>
        <p>
          <strong>b. How you can see or change your account Personal Data.</strong> If You would like to review,
          correct, or update Personal Data that You have previously disclosed to us, You may do so by signing in to your
          Service account or by <a href="#contact-us">contacting us</a>.
        </p>
        <p>
          <strong>c. Your data protection rights.</strong> Depending on your location and subject to applicable law, you
          may have the following rights with regard to the Personal Data we control about you:
        </p>

        <ul>
          <li>
            The right to request confirmation of whether TimeSoft processes Personal Data relating to you, and if so, to
            request a copy of that Personal Data;
          </li>
          <li>
            The right to request that TimeSoft rectifies or updates your Personal Data that is inaccurate, incomplete or
            outdated;
          </li>
          <li>The right to request that TimeSoft erase your Personal Data in certain circumstances provided by law;</li>
          <li>
            The right to request that TimeSoft restrict the use of your Personal Data in certain circumstances, such as
            while TimeSoft considers another request that you have submitted (including a request that TimeSoft make an
            update to your Personal Data); and
          </li>
          <li>
            The right to request that we export to another company, where technically feasible, your Personal Data that
            we hold in order to provide Services to you.
          </li>
        </ul>

        <p>
          Where the processing of your Personal Data is based on your previously given consent, you have the right to
          withdraw your consent at any time. You may also have the right to object to the processing of your Personal
          Data on grounds relating to your particular situation.
        </p>

        <p>
          <strong>d. Process for exercising data protection rights.</strong> In order to exercise your data protection
          rights, you may contact TimeSoft as described in the <a href="#contact-us">Contact Us</a> section below. We
          take each request seriously. We will comply with your request to the extent required by applicable law. We
          will not be able to respond to a request if we no longer hold your Personal Data. If you feel that you have
          not received a satisfactory response from us, you may consult with the data protection authority in your
          country.
        </p>
        <p>
          For your protection, we may need to verify your identity before responding to your request, such as verifying
          that the email address from which you send the request matches your email address that we have on file. If we
          no longer need to process Personal Data about you in order to provide our Services or our Sites, we will not
          maintain, acquire or process additional information in order to identify you for the purpose of responding to
          your request.
        </p>

        <h3 id="security-and-retention">6. Security and Retention.</h3>
        <p>
          We make reasonable efforts to ensure a level of security appropriate to the risk associated with the
          processing of Personal Data. We maintain organizational, technical and administrative measures designed to
          protect Personal Data within our organization against unauthorized access, destruction, loss, alteration or
          misuse. Your Personal Data is only accessible to a limited number of personnel who need access to the
          information to perform their duties. Unfortunately, no data transmission or storage system can be guaranteed
          to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for
          example, if you feel that the security of your account has been compromised), please{' '}
          <a href="#contact-us">contact us</a> immediately.
        </p>
        <p>
          We retain your Personal Data as long as we are providing the Services to you. We retain Personal Data after we
          cease providing Services directly or indirectly to you, to the extent necessary to comply with our legal and
          regulatory obligations, and for the purpose of fraud monitoring, detection and prevention. We also retain
          Personal Data to comply with our tax, accounting, and financial reporting obligations, where we are required
          to retain the data by our contractual commitments to our financial partners, and where data retention is
          mandated by the payment methods that we support. Where we retain data, we do so in accordance with any
          limitation periods and records retention obligations that are imposed by applicable law.
        </p>

        <h3 id="international-data-transfers">7. International Data Transfers.</h3>
        <p>
          We are operating in a global business. Personal Data may be stored and processed in any country where we have
          operations or where we engage service providers. We may transfer Personal Data that we maintain about you to
          recipients in countries other than the country in which the Personal Data was originally collected, including
          to the United States. Those countries may have data protection rules that are different from those of your
          country. However, we will take measures to ensure that any such transfers comply with applicable data
          protection laws and that your Personal Data remains protected to the standards described in this Privacy
          Policy. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security
          authorities in those other countries may be entitled to access your Personal Data.
        </p>

        <p>
          If you are located in the European Economic Area (“EEA”), the UK or Switzerland, we comply with applicable
          laws to provide an adequate level of data protection for the transfer of your Personal Data to the US.
          TimeSoft Inc. is pending certified under the EU-U.S. and the Swiss-U.S. Privacy Shield Framework and adheres
          to the Privacy Shield Principles in connection with personal data transfers from the EEA, the UK and
          Switzerland. For more, see TimeSoft’s{' '}
          <a href="https://savetofuture.com/privacy_shield_policy.html" target="_blank" rel="noreferrer">
            Privacy Shield Policy
          </a>
          . In addition, we have implemented intra-group data transfer agreements which you may view upon request.
        </p>

        <p>
          Where applicable law requires us to ensure that an international data transfer is governed by a data transfer
          mechanism, we use one or more of the following mechanisms: EU Standard Contractual Clauses with a data
          recipient outside the EEA or the UK, verification that the recipient has implemented Binding Corporate Rules,
          or verification that the recipient adheres to the EU-US and Swiss-US Privacy Shield Framework.
        </p>

        <h3 id="use-by-minors">8. Use by Minors.</h3>
        <p>
          The Services are not directed to individuals under the age of thirteen (13), and we request that they not
          provide Personal Data through the Services.
        </p>

        <h3 id="updates-to-this-privacy-policy-and-notifications">
          9. Updates To this Privacy Policy and Notifications.
        </h3>
        <p>
          We may change this Privacy Policy from time to time to reflect new services, changes in our Personal Data
          practices or relevant laws. The “Last updated” legend at the top of this Privacy Policy indicates when this
          Privacy Policy was last revised. Any changes are effective when we post the revised Privacy Policy on the
          Services. We may provide you with disclosures and alerts regarding the Privacy Policy or Personal Data
          collected by posting them on our website and, if you are a User, by contacting you through your Service
          account profile page or Service Dashboard, email address and/or the physical address listed in your Service
          account.
        </p>

        <h3 id="links-to-other-websites">10. Links To Other Websites.</h3>
        <p>
          The Services may provide the ability to connect to other websites. These websites may operate independently
          from us and may have their own privacy notices or policies, which we strongly suggest you review. If any
          linked website is not owned or controlled by us, we are not responsible for its content, any use of the
          website or the privacy practices of the operator of the website.
        </p>

        <h3 id="jurisdiction-specific-provisions">11. Jurisdiction-specific Provisions.</h3>

        <p>
          <strong>a. How We Collect, Use, and Disclose your Personal Information.</strong>
          The <a href="#personal-data-definition">Personal Data We Collect</a> section describes the personal
          information we may have collected over the last 12 months, including the categories of sources of that
          information. We collect this information for the purposes described in the{' '}
          <a href="#how-we-use-personal-data">How We Use Personal Data</a> section. We share this information as
          described in the <a href="#how-we-disclose-personal-data">How We Disclose Personal Data</a> section. Sites
          uses cookies, including advertising cookies, as described in our{' '}
          <a href="https://savetofuture.com/cookie_policy.html" target="_blank" rel="noreferrer">
            Cookie Policy
          </a>
          .
        </p>

        <p>
          <strong>b. Your CCPA Rights and Choices.</strong> As a California consumer and subject to certain limitations
          under the CCPA, you have choices regarding our use and disclosure of your personal information:
        </p>

        <ul>
          <li>
            <strong>Exercising the right to know.</strong> You may request, up to twice in a 12-month period, the
            following information about the personal information we have collected about you during the past 12 months:
            <ul>
              <li>the categories and specific pieces of personal information we have collected about you;</li>
              <li>the categories of sources from which we collected the personal information;</li>
              <li>the business or commercial purpose for which we collected the personal information;</li>
              <li>the categories of third parties with whom we shared the personal information; and</li>
              <li>
                the categories of personal information about you that we disclosed for a business purpose, and the
                categories of third parties to whom we disclosed that information for a business purpose.
              </li>
            </ul>
          </li>
          <li>
            <strong>Exercising the right to delete.</strong> You may request that we delete the personal information we
            have collected from you, subject to certain limitations under applicable law.
          </li>
          <li>
            <strong>Exercising the right to opt-out from a sale.</strong> You may request to opt out of any “sale” of
            your personal information that may take place. As described in <a href="#advertising">Advertising</a>, we do
            not use, share, rent or sell the Personal Data of our Users’ Customers for interest-based advertising. We do
            not sell or rent the Personal Data of our Users, their Customers or our Site visitors.
          </li>
          <li>
            <strong>Non-discrimination.</strong> The CCPA provides that you may not be discriminated against for
            exercising these rights.
          </li>
        </ul>

        <p>
          To submit a request to exercise any of the rights described above, you may contact TimeSoft at{' '}
          <a href="mailto://privacy@savetofuture.com">privacy@savetofuture.com</a>. We may need to verify your identity
          before responding to your request, such as verifying that the email address from which you send the request
          matches your email address that we have on file. Authentication based on a government-issued and valid
          identification document may be required.
        </p>

        <h3 id="contact-us">12. Contact Us</h3>

        <p>
          If You have any questions or complaints about this Privacy Policy, please{' '}
          <a href="https://savetofuture.com/" target="_blank" rel="noreferrer">
            contact us
          </a>{' '}
          electronically or send physical mail to:
        </p>

        <p>
          TimeSoft, Inc.
          <br />
          Organized under the laws of the State of Delaware, USA, and operating under the laws of the USA
          <br />
          9450 SW Gemini Dr,
          <br />
          PMB 20966
          <br />
          Beaverton, Oregon 97008-7105 US
          <br />
          <br />
          Email: support@savetofuture.com
          <br />
          Attention: TIMESOFT, Inc., Legal
        </p>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
