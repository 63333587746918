/* eslint-disable react-hooks/exhaustive-deps */

import { Box, CircularProgress, Divider, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../hooks/useApi';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(3),
  },
  discountBar: {
    backgroundColor: '#515C70',
    width: '100%',
    height: 30,
    borderRadius: 3,
    color: '#FFFFFF',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ten: {
    backgroundColor: '#B7BFCF',
    width: '22.22%',
  },
  twenty: {
    backgroundColor: '#8692A9',
    width: '44.44%',
  },
  thirty: {
    backgroundColor: '#627087',
    width: '66.66%',
  },
  discountBox: {
    backgroundColor: '#F1FEE9',
    fontSize: 24,
    borderRadius: 4,
  },
  pricesLess: {
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
  totalPriceBox: {
    color: '#0064C2',
    fontSize: 24,
    fontWeight: 600,
    border: '2px solid #0064C2',
    borderRadius: 4,
  },
  calculatorGrid: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  calculatorRadioGroup: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
}));

// const StorageDurationSlider = withStyles({
//   track: {
//     height: 3,
//   },
// })(Slider);

// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: '#2E3542',
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//     zIndex: 1,
//   },
// }))(Tooltip);

// function ValueLabelComponent(props) {
//   const { children, open, value } = props;

//   return (
//     <LightTooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
//       {children}
//     </LightTooltip>
//   );
// }

const Pricing = () => {
  const classes = useStyles();
  const api = useApi();
  const { t } = useTranslation();
  const [prices, setPrices] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const [size] = useState('0');
  const [duration] = useState(12);
  const [courier] = useState(false);

  const [setDiscount] = useState(0);
  const [setTotalPrice] = useState(0);

  const getPrices = () => {
    setLoading(true);
    api
      .getPrices()
      .then((response) => {
        setPrices(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleChangeSize = (e) => {
  //   setSize(e.target.value);
  // };

  // const handleDurationChange = (e, value) => {
  //   setDuration(value);
  // };

  // const handleCourierChange = (event) => {
  //   setCourier(event.target.checked);
  // };

  const calculate = () => {
    let totalValue;
    let durationInMonths = duration;

    const price = prices.prices.filter((item) => {
      return item[0] === Number(size);
    });

    const discount = prices.discounts.filter((item) => {
      return durationInMonths >= item[0] && durationInMonths <= item[1];
    });

    totalValue = price[0][2] * durationInMonths;
    totalValue = totalValue - (totalValue * discount[0][2]) / 100;
    totalValue += courier ? prices.courierPrice : 0;

    setDiscount(discount[0][2]);
    setTotalPrice(totalValue);
  };

  useEffect(() => {
    getPrices();
  }, []);

  useEffect(() => {
    if (prices) {
      calculate();
    }
  }, [prices, courier, size, duration]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography component="div">
        <Box fontSize={20} lineHeight={1.1} fontWeight={600}>
          {t('translation:pricing:title')}
        </Box>
      </Typography>

      <Typography component="div">
        <Box mb={2} fontSize={16}>
          {t('translation:pricing:subtitle')}
        </Box>
      </Typography>

      <Divider light />

      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <Box>&nbsp;</Box>
      )}
    </Container>
  );
};

export default Pricing;
