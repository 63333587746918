import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ROUTES from '../../constants/routes';
import { useAuth } from '../../hooks/useAuth';
import { useRouter } from '../../hooks/useRouter';

const INITIAL_STATE = {
  password: '',
  passwordConfirmation: '',
  error: null,
  success: null,
  code: '',
};

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  header: {
    margin: theme.spacing(0, 0, 10, 0),
  },
  title: {
    margin: theme.spacing(0, 0, 4.5, 0),
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(5, 0, 0, 0),
  },
  submit: {
    margin: theme.spacing(3, 0, 5, 0),
  },
  error: {
    color: theme.palette.error.main,
  },
  input: {
    fontSize: 24,
  },
}));

const ResetPassword = () => {
  const router = useRouter();
  const classes = useStyles();
  const auth = useAuth();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState(INITIAL_STATE);
  const code = router.query?.oobCode;

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const { password } = state;

    setLoading(true);

    auth
      .confirmPasswordReset(code, password)
      .then(() => {
        setState({
          ...INITIAL_STATE,
          success: { message: 'Password has been successfully updated.' },
        });

        setTimeout(() => {
          router.history.push(ROUTES.AUTH_SIGN_IN);
        }, 1000);

        setLoading(false);
      })
      .catch((error) => {
        setState({ ...state, error });

        setLoading(false);
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation(!showPasswordConfirmation);
  const handleMouseDownPasswordConfirmation = () => setShowPasswordConfirmation(!showPasswordConfirmation);

  const { password, passwordConfirmation, error } = state;
  const isInvalid = password !== passwordConfirmation || password === '';

  useEffect(() => {
    document.title = 'Change password - allPWD';
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.header} textAlign="center">
        <Box className={classes.title}>
          <Typography component="h1" variant="h4">
            {t('translation:auth:reset:title')}
          </Typography>
        </Box>

        <Box className={classes.subtitle} width={375}>
          <Typography component="h2" variant="body1">
            {t('translation:auth:reset:subtitle')}
          </Typography>
        </Box>
      </Box>

      <Container maxWidth="sm">
        <Box textAlign="center">
          {error && <p className={classes.error}>{error.message}</p>}

          {password.length > 0 && passwordConfirmation.length > 0 && password !== passwordConfirmation && (
            <Typography component="p" variant="body1" className={classes.error}>
              {t('translation:auth:signUp:passwordsError')}
            </Typography>
          )}
        </Box>

        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            type="text"
            style={{ '-webkit-text-security': showPassword ? 'none' : 'disc', marginBottom: 24 }}
            id="password"
            name="password"
            autoComplete="false"
            inputProps={{
              autoComplete: 'false',
            }}
            value={password}
            onChange={onChange}
            placeholder={t('translation:auth:reset:inputPasswordPlaceholder')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            required
            fullWidth
          />

          <TextField
            type="text"
            style={{ '-webkit-text-security': showPasswordConfirmation ? 'none' : 'disc', marginBottom: 24 }}
            id="passwordConfirmation"
            name="passwordConfirmation"
            value={passwordConfirmation}
            autoComplete="false"
            inputProps={{
              autoComplete: 'false',
            }}
            onChange={onChange}
            placeholder={t('translation:auth:reset:inputConfirmPlaceholder')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordConfirmation}
                    onMouseDown={handleMouseDownPasswordConfirmation}
                  >
                    {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            required
            fullWidth
          />

          <Box display="flex" flexDirection="column">
            <Box textAlign="center">
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                disabled={isInvalid}
                className={classes.submit}
              >
                {!isLoading ? t('translation:auth:reset:saveButton') : <CircularProgress color="inherit" size={26} />}
              </Button>
            </Box>
          </Box>
        </form>
      </Container>
    </div>
  );
};

export default ResetPassword;
