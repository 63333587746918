import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 0,
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const TooltipIcon = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    // <ClickAwayListener onClickAway={handleTooltipClose}>
    <Tooltip title={props.title} open={open} placement={props.placement} onClose={handleTooltipClose}>
      <IconButton className={classes.iconButton} onClick={handleTooltipOpen} onMouseOverCapture={handleTooltipOpen}>
        <HelpOutlineOutlinedIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
    // </ClickAwayListener>
  );
};

export default TooltipIcon;
