/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useVaultService } from '../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: '#fff',
    margin: 0,
    bottom: 0,
    borderRadius: '20px 20px 0 0',
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 7,
    position: 'fixed',
    paddingBottom: 80,
    minHeight: '75vh',
  },
  close: {
    backgroundColor: '#E6E6E6',
    margin: '0 auto',
    width: 62,
    height: 4,
    cursor: 'pointer',
  },
  list: {
    width: '100%',
    paddingTop: 10,
  },
  inputWrap: { position: 'relative' },
  iconWrap: { position: 'absolute', left: 10, top: 8, zIndex: 20, color: '#A6AAB0' },
  input: {
    position: 'relative',
    minWidth: 290,
    height: 40,
    background: '#F4F8FC',
    border: '1px solid #E3E3E3',
    borderRadius: '4px 0 0 4px',
    paddingLeft: 34,
    color: '#000',
    fontSize: 16,
    marginBottom: 20,
  },
  btn: {
    textTransform: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    padding: 5,
    opacity: 0.4,
    border: '1px solid transparent',
    '&:hover': {
      opacity: 1,
    },
    '&.active': {
      opacity: 1,
      border: '1px solid #2E3542',
    },
  },
}));

const MobileSearch = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { onClose } = props;
  const { control } = useForm();
  const { searchVaultText, searchType, setSearchVaultText, setSearchType } = useVaultService();
  const [searchText, setSearchText] = useState(searchVaultText);
  const [type, setType] = useState(searchType);

  const init = () => {};

  const onReset = () => {
    setSearchType('mainfields');
    setSearchVaultText('');
    setType('mainfields');
    setSearchText('');
  };

  const onSubmit = () => {
    setSearchType(type);
    setSearchVaultText(searchText);
    onClose();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal open={true} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        />

        <Box
          className={classes.list}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Controller
            as={
              <div className={classes.inputWrap}>
                <div className={classes.iconWrap}>
                  <Search />
                </div>
                <input
                  onChange={(event) => setSearchText(event.target.value)}
                  value={searchText}
                  className={classes.input}
                  placeholder={t('translation:header:search')}
                />
              </div>
            }
            name="search"
            control={control}
            defaultValue={searchVaultText}
          />

          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-around"
            flexGrow={10}
            className={classes.btnWrap}
          >
            <Button
              color="primary"
              size="small"
              className={classNames(classes.btn, type === 'mainfields' ? 'active' : '')}
              onClick={() => setType('mainfields')}
            >
              {t('translation:header:mainfields')}
            </Button>
            <Button
              color="primary"
              size="small"
              className={classNames(classes.btn, type === 'allfields' ? 'active' : '')}
              onClick={() => setType('allfields')}
            >
              {t('translation:header:allfields')}
            </Button>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
            <Button style={{ marginTop: 20 }} variant="contained" onClick={() => onSubmit()} color="primary">
              {t('translation:commonPages:search')}
            </Button>
            <Button style={{ marginTop: 20 }} onClick={() => onReset()} variant="outlined" color="primary">
              {t('translation:commonPages:reset')}
            </Button>
            <Button style={{ marginTop: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
              {t('translation:commonPages:cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MobileSearch;
