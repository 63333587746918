import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import logo from '../assets/logo-stf.svg';

const useStyles = makeStyles(() => ({
  textBold: {
    fontWeight: 'bold',
  },
  root: {
    borderRadius: 4,
    background: '#fff',
    marginLeft: 13,
    marginRight: 13,
    paddingLeft: 18,
    paddingTop: 14,
    paddingBottom: 13,
    cursor: 'pointer',
  },
}));

const SwitchButton = () => {
  const classes = useStyles();
  const height = 30;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      mb={2}
      className={classes.root}
      onClick={() => {
        window.open('https://savetofuture.com/', '_blank');
      }}
    >
      <img alt={logo} src={logo} height={height} style={{ height: height }} />
      <Box fontSize={14} ml={2} lineHeight={1.1}>
        Try SaveToFuture.com <br />
        <span className={classes.textBold}>Secure Capsules</span>
      </Box>
    </Box>
  );
};

export default SwitchButton;
