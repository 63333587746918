import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ListItemStyled = withStyles({
  root: {
    borderRadius: 8,
    marginTop: 4,
    marginBottom: 4,
  },
  selected: {
    backgroundColor: '#E41139!important',
    color: '#FFFFFF',
  },
})(ListItem);

const DialogTitleStyled = withStyles({
  root: {
    paddingBottom: 0,
  },
})(DialogTitle);

const DialogContentStyled = withStyles({
  root: {
    paddingTop: 0,
  },
})(DialogContent);

const LanguageDialog = (props) => {
  const { language, onChange } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const _language = language.split('-')[0];
  const [selectedLanguage, setSelectedLanguage] = React.useState(_language);

  const handleListItemClick = (event, index) => {
    onChange(index);
    setSelectedLanguage(index);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      {props.device ? (
        <Box display="flex" alignItems="center" onClick={handleClickOpen}>
          <LanguageIcon />
          <Box ml={2} onClick={handleClickOpen}>
            {_language.toUpperCase()}
          </Box>
        </Box>
      ) : (
        <Button color="primary" size="large" onClick={handleClickOpen} endIcon={<LanguageIcon />}>
          {_language.toUpperCase()}
        </Button>
      )}

      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogTitleStyled>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitleStyled>

        <DialogContentStyled>
          <Box p={1} pt={0} pb={2}>
            <Box textAlign="center" mb={4}>
              <Typography component="div">
                <Box fontSize={18} fontWeight={600}>
                  {t('translation:header:chooseLanguage')}
                </Box>
              </Typography>
            </Box>

            <Grid container spacing={window.innerWidth > 560 ? 3 : 0}>
              <Grid xs={12} sm={6} md={3} item>
                <Box>
                  <List style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'en'}
                      onClick={(event) => handleListItemClick(event, 'en')}
                    >
                      <ListItemText primary="English" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'es'}
                      onClick={(event) => handleListItemClick(event, 'es')}
                    >
                      <ListItemText primary="Spanish" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'fr'}
                      onClick={(event) => handleListItemClick(event, 'fr')}
                    >
                      <ListItemText primary="French" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'de'}
                      onClick={(event) => handleListItemClick(event, 'de')}
                    >
                      <ListItemText primary="German" />
                    </ListItemStyled>
                  </List>
                </Box>
              </Grid>

              <Grid xs={12} sm={6} md={3} item>
                <Box>
                  <List style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'ko'}
                      onClick={(event) => handleListItemClick(event, 'ko')}
                    >
                      <ListItemText primary="Korean" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'ja'}
                      onClick={(event) => handleListItemClick(event, 'ja')}
                    >
                      <ListItemText primary="Japanese" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'zh'}
                      onClick={(event) => handleListItemClick(event, 'zh')}
                    >
                      <ListItemText primary="Chinese" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'hi'}
                      onClick={(event) => handleListItemClick(event, 'hi')}
                    >
                      <ListItemText primary="Hindi" />
                    </ListItemStyled>
                  </List>
                </Box>
              </Grid>

              <Grid xs={12} sm={6} md={3} item>
                <Box>
                  <List style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'pt'}
                      onClick={(event) => handleListItemClick(event, 'pt')}
                    >
                      <ListItemText primary="Portuguese" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      disabled
                      selected={selectedLanguage === 'id'}
                      onClick={(event) => handleListItemClick(event, 'id')}
                    >
                      <ListItemText primary="Indonesian" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'km'}
                      onClick={(event) => handleListItemClick(event, 'km')}
                    >
                      <ListItemText primary="Khmer" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'ru'}
                      onClick={(event) => handleListItemClick(event, 'ru')}
                    >
                      <ListItemText primary="Russian" />
                    </ListItemStyled>
                  </List>
                </Box>
              </Grid>

              <Grid xs={12} sm={6} md={3} item>
                <Box>
                  <List style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemStyled
                      button
                      disabled
                      selected={selectedLanguage === 'th'}
                      onClick={(event) => handleListItemClick(event, 'th')}
                    >
                      <ListItemText primary="Thai" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      disabled
                      selected={selectedLanguage === 'tr'}
                      onClick={(event) => handleListItemClick(event, 'tr')}
                    >
                      <ListItemText primary="Turkish" />
                    </ListItemStyled>

                    <ListItemStyled
                      button
                      selected={selectedLanguage === 'it'}
                      onClick={(event) => handleListItemClick(event, 'it')}
                    >
                      <ListItemText primary="Italian" />
                    </ListItemStyled>
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContentStyled>
      </Dialog>
    </Box>
  );
};

export default LanguageDialog;
