import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../hooks/useRouter';
import { useSteps } from '../../hooks/useSteps';
import TopUpBalance from './TopUpBalance';
import TopUpMethod from './TopUpMethod';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  stepper: {
    margin: theme.spacing(1, 0),
  },
  stepperConnectorLine: {
    borderColor: '#D2D9E5',
  },
  actionText: {
    color: '#A6AAB0',
    fontSize: 16,
    margin: theme.spacing(0, 3),
    display: 'inline-block',
  },
  contentWrapper: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}));

const getStepContent = (activeStep, handleBack, handleNext) => {
  switch (activeStep) {
    case 0:
      return <TopUpBalance handleBack={handleBack} handleNext={handleNext} />;
    case 1:
      return <TopUpMethod handleBack={handleBack} handleNext={handleNext} />;
    default:
      throw new Error('Unknown step');
  }
};

const StepperStyled = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}))(Stepper);

const TopUp = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const classes = useStyles();
  const steps = new Array(2).fill('');
  const [activeStep, setActiveStep] = useState(0);
  const { setCapsule, INITIAL_STATE } = useSteps();

  // const handleCloseCreateCapsuleLayout = () => {
  //   setCapsule(INITIAL_STATE);
  //   router.history.push(ROUTES.USER_CAPSULES);
  // };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Fragment>
      <CssBaseline />

      <Box className={classes.container}>
        {activeStep >= steps.length ? null : (
          <Box maxWidth={240}>
            <StepperStyled
              activeStep={activeStep}
              className={classes.stepper}
              connector={
                <StepConnector
                  classes={{
                    line: classes.stepperConnectorLine,
                  }}
                />
              }
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{''}</StepLabel>
                </Step>
              ))}
            </StepperStyled>
          </Box>
        )}

        <Box>
          {activeStep >= steps.length ? (
            <Box>
              <Box textAlign="center" mt={8}>
                <Box>
                  <CheckCircleIcon style={{ fontSize: 40, color: '#80D948' }} />
                  <Typography component="h1" gutterBottom style={{ fontSize: 34, marginTop: 16 }}>
                    {t('translation:createCapsule:paymentSuccess')}
                  </Typography>
                </Box>

                <Box mt={6}>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setCapsule(INITIAL_STATE);
                      router.history.push(ROUTES.USER_PROFILE);
                    }}
                  >
                    {t('translation:topUpBalance:goToProfile')}
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={classes.contentWrapper}>{getStepContent(activeStep, handleBack, handleNext)}</Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default TopUp;
