/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { CardNumber, DateMask } from '../../components/TextMasks';
import { useVaultService } from '../../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: '#A6AAB0',
    marginTop: -20,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  description1: {
    color: '#2E3542',
    marginTop: 2,
    fontSize: 14,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  fieldsWrap: {
    marginTop: 30,
    paddingLeft: window.innerWidth > 560 ? 40 : 0,
    paddingRight: window.innerWidth > 560 ? 40 : 0,
    width: '100%',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    borderRadius: 10,
    background: '#F4F8FC',
    color: '#2E3542',
    border: '1px solid #F4F8FC',
    padding: '17px 22px',
    fontSize: 16,
    fontFamily: 'Roboto',
    '&:focus': {
      outline: 'none',
    },
    '&:placeholder': {
      outline: 'none',
      color: '#A6AAB0',
    },
  },
  favBtn: {},
  favText: {
    color: '#A6AAB0',
    fontSize: 14,
    marginLeft: 3,
  },
}));

const INITIAL_STATE = {
  name: '',
  ctype: '',
  ccurr: '',
  cname: '',
  cnumber: '',
  ccvc: '',
  cissue: '',
  cexpire: '',
  fav: false,
  cpin: '',
  desc: '',
};

const CreateBankCard = (props) => {
  const classes = useStyles();
  const vaultService = useVaultService();
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCVC, setShowCVC] = useState(false);
  const [state, setState] = useState(INITIAL_STATE);
  const { currentVault, updateCurrentVault } = vaultService;
  const { onClose, editRowId } = props;

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowCVC = () => setShowCVC(!showCVC);
  const handleMouseDownCVC = () => setShowCVC(!showCVC);

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const toogleFavorite = () => {
    setState({ ...state, fav: !state.fav });
  };

  const init = () => {
    if (editRowId) {
      const currentRecBank = currentVault.rec_bank.find((item) => item.id === editRowId);
      if (currentRecBank) {
        setState({ ...currentRecBank });
      }
    }
  };

  const onSubmit = () => {
    const data = {
      ...state,
      id: uuidv4(),
      cre: new Date().getTime(),
      upd: new Date().getTime(),
    };
    if (editRowId) {
      const currentRecBank = currentVault.rec_bank.find((item) => item.id === editRowId);
      currentVault.rec_bank = currentVault.rec_bank.filter((item) => item.id !== editRowId);
      currentVault.rec_bank.push({
        ...data,
        cre: currentRecBank ? currentRecBank.cre : new Date().getTime(),
        id: editRowId,
      });
    } else {
      currentVault.rec_bank.push(data);
    }
    updateCurrentVault();
    onClose();
  };

  useEffect(() => {
    init();
  }, []);

  const isInvalid = !(state.name && state.cname && state.cnumber && state.ccvc && state.cexpire);

  return (
    <Modal open={true} className={classes.modal} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {editRowId ? 'EDIT' : 'NEW BANK CARD'}
        </Box>
        <Box
          className={classes.description1}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:bankCards')}
        </Box>

        <Box className={classes.fieldsWrap} display="flex" justifyContent="flex-start" alignItems="center">
          <TextField
            type="text"
            id="name"
            name="name"
            value={state.name}
            inputProps={{
              maxLength: 200,
            }}
            color="primary"
            onChange={onChange}
            placeholder={'Bank name'}
            label={'Bank name'}
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />
          <FormControl className={classes.input} style={{ marginBottom: 24 }}>
            <InputLabel id="ctype">{t('translation:commonPages:cardType')}</InputLabel>
            <Select labelId="ctype" id="ctype" name="ctype" value={state.ctype} onChange={onChange}>
              <MenuItem value={'visa'}>Visa</MenuItem>
              <MenuItem value={'mastercard'}>MasterCard</MenuItem>
              <MenuItem value={'AmEx'}>AmEx</MenuItem>
              <MenuItem value={'Diners'}>Diners</MenuItem>
              <MenuItem value={'Union'}>Union</MenuItem>
              <MenuItem value={'Discover'}>Discover</MenuItem>
              <MenuItem value={'JCB'}>JCB</MenuItem>
              <MenuItem value={'Mir'}>Mir</MenuItem>
              <MenuItem value={'Maestro'}>Maestro</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.input} style={{ marginBottom: 24 }}>
            <InputLabel id="ccurr">{t('translation:commonPages:currency')}</InputLabel>
            <Select labelId="ccurr" id="ccurr" name="ccurr" value={state.ccurr} onChange={onChange}>
              <MenuItem value={'AUD'}>AUD</MenuItem>
              <MenuItem value={'BRL'}>BRL</MenuItem>
              <MenuItem value={'CAD'}>CAD</MenuItem>
              <MenuItem value={'CHF'}>CHF</MenuItem>
              <MenuItem value={'CNY'}>CNY</MenuItem>
              <MenuItem value={'DKK'}>DKK</MenuItem>
              <MenuItem value={'EUR'}>EUR</MenuItem>
              <MenuItem value={'GBP'}>GBP</MenuItem>
              <MenuItem value={'HKD'}>HKD</MenuItem>
              <MenuItem value={'INR'}>INR</MenuItem>
              <MenuItem value={'JPY'}>JPY</MenuItem>
              <MenuItem value={'KRW'}>KRW</MenuItem>
              <MenuItem value={'MXN'}>MXN</MenuItem>
              <MenuItem value={'MYR'}>MYR</MenuItem>
              <MenuItem value={'NOK'}>NOK</MenuItem>
              <MenuItem value={'NZD'}>NZD</MenuItem>
              <MenuItem value={'RUB'}>RUB</MenuItem>
              <MenuItem value={'PLN'}>PLN</MenuItem>
              <MenuItem value={'SEK'}>SEK</MenuItem>
              <MenuItem value={'SGD'}>SGD</MenuItem>
              <MenuItem value={'THB'}>THB</MenuItem>
              <MenuItem value={'TRY'}>TRY</MenuItem>
              <MenuItem value={'TWD'}>TWD</MenuItem>
              <MenuItem value={'USD'}>USD</MenuItem>
              <MenuItem value={'ZAR'}>ZAR</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="text"
            id="cname"
            name="cname"
            value={state.cname}
            inputProps={{
              maxLength: 200,
            }}
            color="primary"
            onChange={onChange}
            placeholder={t('translation:commonPages:cardOwner')}
            label={t('translation:commonPages:cardOwner')}
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            type="text"
            id="cnumber"
            name="cnumber"
            value={state.cnumber}
            inputProps={{
              maxLength: 200,
            }}
            color="primary"
            onChange={onChange}
            placeholder={t('translation:commonPages:cardNumber')}
            label={t('translation:commonPages:cardNumber')}
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
              inputComponent: CardNumber,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            type="text"
            id="ccvc"
            name="ccvc"
            value={state.ccvc}
            inputProps={{
              maxLength: 4,
            }}
            color="primary"
            onChange={onChange}
            placeholder={'CVC/CVV'}
            label={'CVC/CVV'}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowCVC} onMouseDown={handleMouseDownCVC}>
                    {showCVC ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            style={{ '-webkit-text-security': showCVC ? 'none' : 'disc', marginBottom: 24 }}
          />
          <TextField
            type="text"
            id="cissue"
            name="cissue"
            value={state.cissue}
            inputProps={{
              maxLength: 200,
            }}
            color="primary"
            onChange={onChange}
            placeholder={t('translation:commonPages:issueDate')}
            label={t('translation:commonPages:issueDate')}
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
              inputComponent: DateMask,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            type="text"
            id="cexpire"
            name="cexpire"
            value={state.cexpire}
            inputProps={{
              maxLength: 200,
            }}
            color="primary"
            onChange={onChange}
            placeholder={t('translation:commonPages:exdate')}
            label={t('translation:commonPages:exdate')}
            fullWidth
            required
            InputProps={{
              classes: {
                input: classes.input,
              },
              inputComponent: DateMask,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            type="text"
            style={{ '-webkit-text-security': showPassword ? 'none' : 'disc', marginBottom: 24 }}
            id="cpin"
            name="cpin"
            inputProps={{
              maxLength: 6,
              autoComplete: 'false',
            }}
            autoComplete="false"
            value={state.cpin}
            onChange={onChange}
            placeholder={'PIN'}
            label={'PIN'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            fullWidth
          />
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={2}
            maxLength={264}
            placeholder={t('translation:commonPages:desc')}
            className={classes.textarea}
            id="desc"
            name="desc"
            value={state.desc}
            onChange={onChange}
          />
          <Box
            display="flex"
            mt={5}
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{ width: '100%', marginLeft: -20 }}
          >
            <IconButton
              className={classes.favBtn}
              aria-label="view list"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ color: state.fav ? '#E41139' : '#A6AAB0' }}
              onClick={toogleFavorite}
            >
              {state.fav ? <Favorite /> : <FavoriteBorderOutlined />}
            </IconButton>
            <span className={classes.favText}>{t('translation:commonPages:addFav')}</span>
          </Box>
        </Box>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
          mt={7}
          mb={5}
        >
          <Button style={{ marginRight: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
            {t('translation:commonPages:cancel')}
          </Button>
          <Button variant="contained" disabled={isInvalid || isLoading} onClick={() => onSubmit()} color="primary">
            {t('translation:commonPages:save')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateBankCard;
