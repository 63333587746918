import moment from 'moment';

export const exportToJson = (checkArr, data, fields) => {
  const exportObj = checkArr
    .filter((item) => item.checked)
    .map((item) => {
      const found = data.find((i) => i.id === item.id);
      if (found) {
        let returnObj = {};
        // eslint-disable-next-line
        fields.map((field) => {
          returnObj = { ...returnObj, [field]: found[field] };
        });
        return returnObj;
      }
      return null;
    })
    .filter((item) => !!item);

  const time = moment().format('YYYYMMDD-HHmm');
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj)));
  element.setAttribute('download', `allPWD-${time}.json`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
