/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useVaultService } from '../../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: '#A6AAB0',
    marginTop: -20,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  description1: {
    color: '#2E3542',
    marginTop: 2,
    fontSize: 14,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  fieldsWrap: {
    marginTop: 30,
    paddingLeft: window.innerWidth > 560 ? 40 : 0,
    paddingRight: window.innerWidth > 560 ? 40 : 0,
    width: '100%',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    borderRadius: 10,
    background: '#F4F8FC',
    color: '#2E3542',
    border: '1px solid #F4F8FC',
    padding: '17px 22px',
    fontSize: 16,
    fontFamily: 'Roboto',
    '&:focus': {
      outline: 'none',
    },
    '&:placeholder': {
      outline: 'none',
      color: '#A6AAB0',
    },
  },
  favBtn: {},
  favText: {
    color: '#A6AAB0',
    fontSize: 14,
    marginLeft: 3,
  },
}));

const INITIAL_STATE = {
  url: '',
  pwd: '',
  type: '',
  login: '',
  sn: '',
  desc: '',
  fav: false,
  fa2: '',
};

const CreateWebSitesLogins = (props) => {
  const classes = useStyles();
  const vaultService = useVaultService();
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState(INITIAL_STATE);
  const { currentVault, updateCurrentVault } = vaultService;
  const { onClose, editRowId } = props;

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const toogleFavorite = () => {
    setState({ ...state, fav: !state.fav });
  };

  const init = () => {
    if (editRowId) {
      const currentRecWeb = currentVault.rec_web.find((item) => item.id === editRowId);
      if (currentRecWeb) {
        setState({ ...currentRecWeb });
      }
    }
  };

  const onSubmit = () => {
    const data = {
      ...state,
      id: uuidv4(),
      cre: new Date().getTime(),
      upd: new Date().getTime(),
    };
    if (editRowId) {
      const currentRecWeb = currentVault.rec_web.find((item) => item.id === editRowId);
      currentVault.rec_web = currentVault.rec_web.filter((item) => item.id !== editRowId);
      currentVault.rec_web.push({
        ...data,
        cre: currentRecWeb ? currentRecWeb.cre : new Date().getTime(),
        id: editRowId,
      });
    } else {
      currentVault.rec_web.push(data);
    }
    updateCurrentVault();
    onClose();
  };

  useEffect(() => {
    init();
  }, []);

  const isInvalid = !(
    state.url &&
    state.type &&
    ((state.type === '1' && state.login && state.pwd) || (state.type === '2' && state.sn && state.login))
  );

  return (
    <Modal open={true} className={classes.modal} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {editRowId ? 'EDIT' : 'NEW WEBSITE'}
        </Box>
        <Box
          className={classes.description1}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:websiteLogins')}
        </Box>

        <Box className={classes.fieldsWrap} display="flex" justifyContent="flex-start" alignItems="center">
          <TextField
            type="text"
            id="url"
            name="url"
            value={state.url}
            inputProps={{
              maxLength: 200,
            }}
            color="primary"
            onChange={onChange}
            placeholder={'Web site address (URL)'}
            label={'Web site address (URL)'}
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />
          <FormControl className={classes.input} style={{ marginBottom: 24 }}>
            <InputLabel id="type">{t('translation:commonPages:typeOfLogin')}</InputLabel>
            <Select labelId="type" id="type" name="type" value={state.type} onChange={onChange}>
              <MenuItem value={'1'}>{t('translation:commonPages:type1')}</MenuItem>
              <MenuItem value={'2'}>{t('translation:commonPages:type2')}</MenuItem>
            </Select>
          </FormControl>
          {state.type === '1' && (
            <TextField
              type="text"
              id="login"
              name="login"
              inputProps={{
                maxLength: 64,
                autoComplete: 'false',
              }}
              autoComplete="false"
              value={state.login}
              color="primary"
              onChange={onChange}
              placeholder={t('translation:commonPages:type1')}
              label={t('translation:commonPages:type1')}
              required
              fullWidth
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              style={{ marginBottom: 24 }}
            />
          )}
          {state.type === '1' && (
            <TextField
              type="text"
              style={{ '-webkit-text-security': showPassword ? 'none' : 'disc', marginBottom: 24 }}
              id="pwd"
              name="pwd"
              inputProps={{
                maxLength: 64,
                autoComplete: 'false',
              }}
              autoComplete="false"
              value={state.pwd}
              onChange={onChange}
              placeholder={t('translation:auth:signIn:inputPasswordPlaceholder')}
              label={t('translation:commonPages:password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  input: classes.input,
                },
              }}
              required
              fullWidth
            />
          )}
          {state.type === '1' && (
            <FormControl className={classes.input} style={{ marginBottom: 24 }}>
              <InputLabel id="fa2">2-FA</InputLabel>
              <Select labelId="fa2" id="fa2" name="fa2" value={state.fa2} onChange={onChange}>
                <MenuItem value={'null'}>No 2FA</MenuItem>
                <MenuItem value={'ga'}>Google Authenticator</MenuItem>
                <MenuItem value={'ma'}>Microsoft Authenticator</MenuItem>
                <MenuItem value={'sms'}>SMS</MenuItem>
                <MenuItem value={'mail'}>Email</MenuItem>
                <MenuItem value={'mob'}>Mobile</MenuItem>
                <MenuItem value={'hw'}>Hardware</MenuItem>
                <MenuItem value={'x'}>other</MenuItem>
              </Select>
            </FormControl>
          )}
          {state.type === '2' && (
            <FormControl className={classes.input} style={{ marginBottom: 24 }}>
              <InputLabel id="sn">{t('translation:commonPages:type2')}</InputLabel>
              <Select labelId="sn" id="sn" name="sn" value={state.sn} onChange={onChange}>
                <MenuItem value={'Intagram'}>Intagram</MenuItem>
                <MenuItem value={'Facebook'}>Facebook</MenuItem>
                <MenuItem value={'Google+'}>Google+</MenuItem>
                <MenuItem value={'Twitter'}>Twitter</MenuItem>
                <MenuItem value={'GitHub'}>GitHub</MenuItem>
                <MenuItem value={'LinkedIn'}>LinkedIn</MenuItem>
                <MenuItem value={'Apple'}>Apple</MenuItem>
                <MenuItem value={'Microsoft'}>Microsoft</MenuItem>
                <MenuItem value={'Shopify'}>Shopify</MenuItem>
                <MenuItem value={'Yahoo'}>Yahoo</MenuItem>
                <MenuItem value={'Alipay'}>Alipay</MenuItem>
                <MenuItem value={'VKontakte'}>VKontakte</MenuItem>
                <MenuItem value={'Reddit'}>Reddit</MenuItem>
                <MenuItem value={'Amazon'}>Amazon</MenuItem>
                <MenuItem value={'PayPal'}>PayPal</MenuItem>
                <MenuItem value={'Yandex'}>Yandex</MenuItem>
                <MenuItem value={'Salesforce'}>Salesforce</MenuItem>
                <MenuItem value={'Taobao'}>Taobao</MenuItem>
                <MenuItem value={'AOL'}>AOL</MenuItem>
                <MenuItem value={'KakaoTalk'}>KakaoTalk</MenuItem>
                <MenuItem value={'Line'}>Line</MenuItem>
                <MenuItem value={'QQ'}>QQ</MenuItem>
                <MenuItem value={'WeChat'}>WeChat</MenuItem>
                <MenuItem value={'Sina Weibo'}>Sina Weibo</MenuItem>
              </Select>
            </FormControl>
          )}
          {state.type === '2' && (
            <TextField
              type="text"
              id="login"
              name="login"
              inputProps={{
                maxLength: 64,
                autoComplete: 'false',
              }}
              value={state.login}
              color="primary"
              onChange={onChange}
              placeholder={t('translation:commonPages:username')}
              label={t('translation:commonPages:username')}
              autoComplete="false"
              required
              fullWidth
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              style={{ marginBottom: 40 }}
            />
          )}
          {state.type && (
            <TextareaAutosize
              aria-label="minimum height"
              rowsMin={2}
              maxLength={264}
              placeholder={t('translation:commonPages:desc')}
              className={classes.textarea}
              id="desc"
              name="desc"
              value={state.desc}
              onChange={onChange}
            />
          )}
          {state.type && (
            <Box
              display="flex"
              mt={5}
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              style={{ width: '100%', marginLeft: -20 }}
            >
              <IconButton
                className={classes.favBtn}
                aria-label="view list"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                style={{ color: state.fav ? '#E41139' : '#A6AAB0' }}
                onClick={toogleFavorite}
              >
                {state.fav ? <Favorite /> : <FavoriteBorderOutlined />}
              </IconButton>
              <span className={classes.favText}>{t('translation:commonPages:addFav')}</span>
            </Box>
          )}
        </Box>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
          mt={7}
          mb={5}
        >
          <Button style={{ marginRight: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
            {t('translation:commonPages:cancel')}
          </Button>
          <Button variant="contained" disabled={isInvalid || isLoading} onClick={() => onSubmit()} color="primary">
            {t('translation:commonPages:save')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateWebSitesLogins;
