/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, IconButton, InputAdornment, Modal, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { INITIAL_HIDDEN_VAULT_STATE, useVaultService } from '../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: '#A6AAB0',
    marginTop: -20,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  description1: {
    color: '#2E3542',
    marginTop: 37,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  description2: {
    color: '#A6AAB0',
    marginTop: 23,
    fontSize: 14,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  passwordField: {
    marginTop: 60,
    paddingLeft: window.innerWidth > 560 ? 40 : 0,
    paddingRight: window.innerWidth > 560 ? 40 : 0,
    width: '100%',
  },
  input: {},
  error: {
    fontSize: 16,
    color: '#E41139',
    textAlign: 'center',
  },
  capsLock: {
    fontSize: 16,
    color: '#af6100',
    textAlign: 'center',
  },
}));

const OpenHiddenVault = (props) => {
  const classes = useStyles();
  const vaultService = useVaultService();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showCapsLockMessage, setShowCapsLockMessage] = useState(false);
  const { hiddenVaults, hiddenVaultsPassword, mainVaultPassword } = vaultService;
  const { onClose } = props;

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onChange = (event) => {
    setPassword(event.target.value);
  };

  const init = () => {
    setTimeout(() => {
      const passwordEl = document.getElementById('password');

      if (passwordEl) {
        passwordEl.addEventListener('keyup', function (e) {
          if (e.getModifierState('CapsLock')) {
            setShowCapsLockMessage(true);
          } else {
            setShowCapsLockMessage(false);
          }
        });
      }
    }, 1000);
  };

  const onSubmit = () => {
    const foundHiddenVaultPassword = hiddenVaultsPassword.find(
      (item) => item.password === password.split(' ').join('')
    );
    if (foundHiddenVaultPassword || mainVaultPassword === password.split(' ').join('')) {
      setError('Vault is already open');
      return;
    }
    setLoading(true);
    vaultService
      .getVault(
        {
          password: password.split(' ').join(''),
        },
        false
      )
      .then((res) => {
        if (res === 'create_new_hidden_vault') {
          const initialState = cloneDeep(INITIAL_HIDDEN_VAULT_STATE);
          const hiddenVault = {
            ...initialState,
            name: `Hidden vault ${hiddenVaults.length + 1}`,
            id: uuidv4(),
          };
          vaultService.updateVault(
            {
              password: password.split(' ').join(''),
              version: 1,
              file: hiddenVault,
            },
            hiddenVault
          );
          onClose();
          setLoading(false);
        } else {
          onClose();
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    init();
  }, []);

  const isInvalid = password === '' || password.split(' ').join('').length < 4;

  return (
    <Modal open={true} className={classes.modal} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:openHidden')}
        </Box>
        <Box
          className={classes.description1}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:openHiddenDesc1')}
        </Box>
        <Box
          className={classes.description2}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:openHiddenDesc2')}
        </Box>
        <Box className={classes.passwordField}>
          {showCapsLockMessage && <Box className={classes.capsLock}>{t('translation:commonPages:capsLockOn')}</Box>}
          <TextField
            type="text"
            style={{ '-webkit-text-security': showPassword ? 'none' : 'disc' }}
            id="password"
            name="password"
            autoComplete="false"
            autoFocus={true}
            value={password}
            onChange={onChange}
            inputProps={{
              maxLength: 64,
              autoComplete: 'false',
            }}
            placeholder={t('translation:auth:signIn:inputPasswordPlaceholder')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            required
            fullWidth
          />
          {error && <Box className={classes.error}>{error}</Box>}
        </Box>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          <Button style={{ marginRight: 20 }} onClick={() => onClose()} variant="outlined" color="primary">
            {t('translation:commonPages:cancel')}
          </Button>
          <Button variant="contained" disabled={isInvalid || isLoading} onClick={() => onSubmit()} color="primary">
            {t('translation:commonPages:open')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OpenHiddenVault;
