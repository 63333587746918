/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';
import { useVaultService } from '../hooks/useVaultService';
import OpenHiddenVault from './OpenHiddenVault';
import VaultItem from './VaultItem';

const drawerWidth = 304;

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  activitySidebar: {
    position: 'relative',
    height: '100vh',
    backgroundColor: '#FFF',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mobileSidebar: {
    position: 'absolute',
  },
  activitySidebarClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0),
    },
  },
  hiddenVault: {
    background: '#F4F8FC',
  },
  secondaryText: {
    width: '100%',
    color: '#A6AAB0',
    fontSize: 12,
    marginBottom: 5,
  },
  createVoult: {
    height: 55,
    width: '100%',
    border: '1px dashed #E41139',
    color: '#E41139',
    fontSize: 16,
    borderRadius: 4,
    cursor: 'pointer',
  },
  closeAllWrap: {
    position: 'fixed',
    width: 'calc(304px - 40px)',
    right: 20,
    bottom: 10,
    backgroundColor: 'rgba(46, 53, 66, 0.7)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 3,
    minHeight: 55,
  },
  closeAllText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
  },
  closeAllBtn: {
    width: 25,
    height: 25,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'white',
    marginLeft: 20,
    zIndex: 10,
  },
}));

const ActivitySidebar = (props) => {
  const classes = useStyles();
  const vaultService = useVaultService();
  const auth = useAuth();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showCloseAll, setShowCloseAll] = useState(true);
  const { currentVault, mainVault, hiddenVaults, selectVault, removeHiddenVault, getVault, removeAllHiddenVault } =
    vaultService;

  const init = () => {
    if (auth.password) {
      setLoading(true);
      getVault(
        {
          password: auth.password,
        },
        true
      ).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    init();
  }, [auth.passwordHash]);

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={props.isActivityOpened}
      PaperProps={{
        elevation: 1,
      }}
      classes={{
        paper: clsx(
          classes.activitySidebar,
          !props.isActivityOpened && classes.activitySidebarClose,
          props.mobile && classes.mobileSidebar
        ),
      }}
    >
      <div className={classes.appBarSpacer} />

      <Box display="flex" style={{ height: '100%' }}>
        {isLoading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" flex={1}>
            <Box p={2.3} display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
              <Box
                className={classes.secondaryText}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                {hiddenVaults.length + 1}{' '}
                {hiddenVaults.length + 1 === 1 ? t('translation:commonPages:item') : t('translation:commonPages:items')}
              </Box>
              {mainVault && (
                <VaultItem
                  name={mainVault.name}
                  isActive={mainVault.id === currentVault.id}
                  isMain={true}
                  onSelect={() => selectVault(mainVault)}
                />
              )}
            </Box>
            <Box
              className={classes.hiddenVault}
              p={2.3}
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box
                className={classes.createVoult}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                id="open-vault"
                mb={1.8}
                onClick={() => setModalIsOpen(true)}
              >
                {t('translation:commonPages:openHiddenVault')}
              </Box>
              {modalIsOpen && <OpenHiddenVault onClose={() => setModalIsOpen(false)} />}
              {hiddenVaults.map((vault, key) => (
                <VaultItem
                  onClose={() => removeHiddenVault(vault)}
                  onSelect={() => selectVault(vault)}
                  key={key}
                  name={vault.name}
                  isActive={vault.id === currentVault.id}
                  isMain={false}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      {props.isActivityOpened && hiddenVaults.length && showCloseAll ? (
        <div
          className={classes.closeAllWrap}
          onClick={() => {
            removeAllHiddenVault();
          }}
        >
          <p className={classes.closeAllText}>{t('translation:commonPages:closeAll')}</p>
          <div className={classes.closeAllBtn} onClick={() => setShowCloseAll(false)}>
            <Close style={{ fontSize: 16, color: '#2E3542' }} />
          </div>
        </div>
      ) : null}
    </Drawer>
  );
};

export default ActivitySidebar;
