import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import { useRouter } from '../hooks/useRouter';
import { useVaultService } from '../hooks/useVaultService';
import AuthenticationLayout from '../layouts/AuthenticationLayout';
import MainLayout from '../layouts/MainLayout';
import WizardLayout from '../layouts/WizardLayout';
import Authentication from './Authentication';
import BankCards from './BankCards';
import Contacts from './Contacts';
import CryptoWallets from './CryptoWallets';
import Dashboard from './Dashboard';
import Faq from './Faq';
import FileAccessPassword from './FileAccessPasswords';
import HardwareDevices from './HardwareDevices';
import NotFound from './NotFound';
import OtherLogins from './OtherLogins';
import Pay from './Pay';
import Pricing from './Pricing';
import PrivacyPolicy from './PrivacyPolicy';
import Profile from './Profile';
import PaymentsHistory from './Profile/PaymentsHistory';
import ServerLogins from './ServerLogins';
import Support from './Support';
import Terms from './Terms';
import TopUp from './TopUp';

import firebase from 'firebase/app'; // for the whole Firebase Web App
import 'firebase/auth'; // for Firebase Authentication
import WebSitesLogin from './WebSitesLogins';

/**
 * Private route HOC component
 * @param Layout
 * @param Container
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
function PrivateRoute(props) {
  const { layout: Layout, container: Container, ...rest } = props;
  const auth = useAuth();
  const router = useRouter();
  const parentProps = props;

  firebase.analytics().logEvent('screen_view', { screen_name: router.pathname });

  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.user && auth.user.emailVerified ? (
          <Layout
            container={<Container />}
            {...props}
            openRightBar={parentProps.openRightBar}
            onClosePromo={parentProps.onClosePromo}
            changePage={parentProps.changePage}
          />
        ) : auth.user && !auth.user.emailVerified ? (
          <Redirect to={ROUTES.AUTH_VERIFY_PLEASE} />
        ) : (
          <Redirect to={ROUTES.AUTH_SIGN_IN} />
        );
      }}
    />
  );
}

/**
 * Public Route HOC component
 * @param Layout
 * @param Container
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
function PublicRoute({ layout: Layout, container: Container, ...rest }) {
  const auth = useAuth();
  const router = useRouter();

  firebase.analytics().logEvent('screen_view', { screen_name: router.pathname });

  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.user && auth.user.emailVerified ? (
          <Redirect to={ROUTES.ROOT} />
        ) : (
          <Layout container={<Container />} {...props} />
        );
      }}
    />
  );
}

/**
 * Application routing main component
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => {
  const auth = useAuth();
  const api = useApi();
  const { currentVault } = useVaultService();
  const [run, setRun] = useState(false);
  const [changePage, setChangePage] = useState(false);
  const [openRightBar, setOpenRightBar] = useState(false);
  const [isTutorDone, setIsTutorDone] = useState(true);
  const [promoClosed, serPromoClosed] = useState(false);
  const { t } = useTranslation();

  const tutorialSteps = [
    {
      target: '#vault-name',
      hideCloseButton: true,
      content: (
        <div>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s1p1')}</p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s1p2')}</p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s1p3')}</p>
        </div>
      ),
    },
    {
      target: '#vault-edit',
      hideCloseButton: true,
      content: (
        <div>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s2p1')}</p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s2p2')}</p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s2p3')}</p>
        </div>
      ),
    },
    {
      target: '#menu-vault',
      hideCloseButton: true,
      content: (
        <div>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s3p1')}</p>
        </div>
      ),
    },
    {
      target: '#create-vault',
      hideCloseButton: true,
      content: (
        <div>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s4p1')}</p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s4p2')}</p>
        </div>
      ),
    },
    {
      target: '#vault-icon',
      hideCloseButton: true,
      content: (
        <div>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s5p1')}</p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s5p2')}</p>
        </div>
      ),
    },
    {
      target: '#open-vault',
      hideCloseButton: true,
      content: (
        <div>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s6p1')}</p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>{t('translation:tutorial:s6p2')}</p>
        </div>
      ),
    },
  ];

  const onClosePromo = () => {
    serPromoClosed(true);
  };

  const chageOverflow = () => {
    const el = document.getElementById('main-layout');
    const el1 = document.getElementById('menubar');
    const el2 = document.getElementById('menubarmobile');
    if (el) {
      el.style.overflow = 'auto';
    }
    if (el1) {
      el1.style.overflow = 'auto';
    }
    if (el2) {
      el2.style.overflow = 'auto';
    }
  };

  const changeStep = (data) => {
    if (data.index === 4) {
      setOpenRightBar(true);
    }
    if (data.index === 0 && data.action === 'reset') {
      api.setUserData({ key: 'isTutorDone', value: 'true' });
      chageOverflow();
    }
  };

  const initUser = () => {
    api.getUser().then((response) => {
      if (response.data.data?.isTutorDone && response.data.data.isTutorDone === 'true') {
        setIsTutorDone(true);
      } else {
        setIsTutorDone(false);
      }
    });
  };

  useEffect(() => {
    if (auth.isReady) {
      initUser();
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    // if (currentVault && isTutorDone && promoClosed) {
    if (currentVault && !isTutorDone && promoClosed && window.innerWidth > 560) {
      setChangePage(true);
      const runId = setInterval(() => {
        const element = document.getElementById('vault-name');
        if (element) {
          setRun(true);
          clearInterval(runId);
        }
      }, 500);
      const id = setInterval(() => {
        const element = document.getElementsByClassName('react-joyride__beacon');
        if (element && element[0]) {
          element[0].click();
          clearInterval(id);
        }
      }, 1000);
    } else if (currentVault) {
      setTimeout(() => {
        chageOverflow();
      }, 2000);
    }
  }, [currentVault, isTutorDone, promoClosed]);

  return auth.isLoading ? (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <CircularProgress color="inherit" size={40} />
    </Box>
  ) : (
    <BrowserRouter>
      <Joyride
        steps={tutorialSteps}
        run={run}
        continuous={true}
        spotlightClicks={true}
        styles={{ options: { zIndex: 1300 } }}
        callback={changeStep}
        locale={{
          back: t('translation:tutorial:back'),
          next: t('translation:tutorial:next'),
          last: t('translation:tutorial:last'),
        }}
      />
      <Switch>
        <PrivateRoute exact path={[ROUTES.ROOT, ROUTES.USER_DASHBOARD]} layout={MainLayout} onClosePromo={onClosePromo} changePage={changePage} container={Dashboard} />
        <PrivateRoute
          path={ROUTES.WEB_SITES_LOGIN}
          layout={MainLayout}
          container={WebSitesLogin}
          openRightBar={openRightBar}
          onClosePromo={onClosePromo}
        />
        <PrivateRoute path={ROUTES.SERVER_LOGINS} layout={MainLayout} container={ServerLogins} />
        <PrivateRoute path={ROUTES.FILE_ACCESS_PASSWORDS} layout={MainLayout} container={FileAccessPassword} />
        <PrivateRoute path={ROUTES.BANK_CARDS} layout={MainLayout} container={BankCards} />
        <PrivateRoute path={ROUTES.CRYPTO_WALLETS} layout={MainLayout} container={CryptoWallets} />
        <PrivateRoute path={ROUTES.HARDWARE_DEVICES} layout={MainLayout} container={HardwareDevices} />
        <PrivateRoute path={ROUTES.OTHER_LOGINS} layout={MainLayout} container={OtherLogins} />
        <PrivateRoute path={ROUTES.CONTACTS} layout={MainLayout} container={Contacts} />
        <PrivateRoute path={ROUTES.USER_PROFILE_PAYMENTS_HISTORY} layout={MainLayout} container={PaymentsHistory} />
        <PrivateRoute path={ROUTES.USER_PROFILE_TOP_UP_BALANCE} layout={WizardLayout} container={TopUp} />
        <PrivateRoute path={ROUTES.USER_PROFILE} layout={MainLayout} container={Profile} />
        <PrivateRoute path={ROUTES.USER_PRICING} layout={MainLayout} container={Pricing} />
        <PrivateRoute path={ROUTES.USER_FAQ} layout={MainLayout} container={Faq} />
        <PrivateRoute path={ROUTES.USER_SUPPORT} layout={MainLayout} container={Support} />
        <PrivateRoute path={ROUTES.USER_PRIVACY_POLICY} layout={MainLayout} container={PrivacyPolicy} />
        <PrivateRoute path={ROUTES.USER_TERMS_OF_SERVICE} layout={MainLayout} container={Terms} />
        <PrivateRoute path={ROUTES.USER_PAY} layout={WizardLayout} container={Pay} />
        <PublicRoute path={ROUTES.AUTH} layout={AuthenticationLayout} container={Authentication} />
        <PublicRoute path={'/index.html'} layout={AuthenticationLayout} container={Authentication} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
