/* !!! NEVER CHANGE THIS SOURCE CODE FOR ALLPWD PROJECT !!! */
/* !!! IT IS IN PRODUCITON !!! */
import { CSALT } from "../constants/encoding";

const getKeyMaterial = async (password) => {
    return window.crypto.subtle.importKey(
        "raw", 
        password, 
        {name: "PBKDF2"}, 
        false, 
        ["deriveBits", "deriveKey"]
    );
}

const getKey = async (keyMaterial, salt) => {
    return window.crypto.subtle.deriveKey(
        {
            "name": "PBKDF2",
            salt: salt, 
            "iterations": 100000,
            "hash": "SHA-256"
        },
        keyMaterial,
        { "name": "AES-CBC", "length": 256},
        true,
        [ "encrypt", "decrypt" ]
    );
}

const makeid = (length) => {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
   return result;
}

export const encrypt = async (data, password, uid) => {
    const passwordBytes = new TextEncoder().encode(password);
    const keyMaterial = await getKeyMaterial(passwordBytes);
	const key = await getKey(keyMaterial, CSALT);
    const digest = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(uid));
    const iv = new Uint8Array(digest, 0, 16);
    
    const encoder = new TextEncoder();
    const _data = makeid(16) + JSON.stringify(data);
    const encoded = encoder.encode(_data)
    const cipher = await window.crypto.subtle.encrypt({
        name: 'AES-CBC',
        iv
    }, key, encoded)

    return cipher; 
}

export const decrypt = async (cipher, password, uid) => {
    const passwordBytes = new TextEncoder().encode(password);
    const keyMaterial = await getKeyMaterial(passwordBytes);
	const key = await getKey(keyMaterial, CSALT);
    const digest = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(uid));
    const iv = new Uint8Array(digest, 0, 16);

    const encoded = await window.crypto.subtle.decrypt({
        name: 'AES-CBC',
        iv
    }, key, cipher);
    const decoder = new TextDecoder();
    const result = decoder.decode(encoded);

    return JSON.parse(result.slice(16)); 
}
