/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Modal, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { INITIAL_HIDDEN_VAULT_STATE, useVaultService } from '../hooks/useVaultService';

const useStyles = makeStyles(() => ({
  root: {
    width: window.innerWidth > 560 ? '50%' : '100%',
    minHeight: window.innerWidth > 560 ? 0 : '100%',
    maxWidth: 527,
    minWidth: 317,
    background: '#fff',
    margin: '0 auto',
    borderRadius: window.innerWidth > 560 ? 10 : 0,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.15)',
    padding: 25,
  },
  modal: {
    overflow: 'auto',
    paddingTop: window.innerWidth > 560 ? '10vh' : 0,
    paddingBottom: window.innerWidth > 560 ? '10vh' : 0,
  },
  close: {
    color: '#C1C4C9',
    marginTop: 3,
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    color: 'red',
    marginTop: -20,
    fontSize: 18,
    marginLeft: 40,
    marginRight: 40,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  description1: {
    color: 'red',
    marginTop: 37,
    fontSize: 18,
    marginLeft: 40,
    fontWeight: 'bold',
    marginRight: 40,
    textAlign: 'center',
  },
  description2: {
    color: '#A6AAB0',
    marginTop: 23,
    fontSize: 14,
    marginLeft: 40,
    marginRight: 40,
    textAlign: 'center',
  },
  passwordField: {
    marginTop: 60,
    paddingLeft: window.innerWidth > 560 ? 40 : 0,
    paddingRight: window.innerWidth > 560 ? 40 : 0,
    width: '100%',
  },
  input: {},
  error: {
    fontSize: 16,
    color: '#E41139',
    textAlign: 'center',
  },
  capsLock: {
    fontSize: 16,
    color: '#af6100',
    textAlign: 'center',
  },
}));

const VersionError = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose } = props;

  const init = () => {
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal open={true} className={classes.modal} onClose={() => onClose()}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          onClick={() => onClose()}
          className={classes.close}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Close />
        </Box>
        <Box
          className={classes.title}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:versionError')}
        </Box>
        <Box
          className={classes.description1}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          {t('translation:commonPages:versionErrorMessage')}
        </Box>
        <Box
          className={classes.passwordField}
          display="flex"
          flexDirection="columm"
          justifyContent="center"
          alignItems="center"
        >
          <Button variant="contained" onClick={() => onClose()} color="primary">
            {t('translation:commonPages:ok')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default VersionError;
