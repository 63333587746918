import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F4F8FC',
    fontSize: 16,
  },
  body: {
    fontSize: 14,
    padding: '2px 16px 2px 16px',
  },
}))(TableCell);

const PaymentsHistory = () => {
  const classes = useStyles();
  const api = useApi();
  const auth = useAuth();
  const { t } = useTranslation();
  const [payments, setPayments] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [isDisabledMore, setDisabledMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take] = useState(10);

  const handleClickLoadMore = () => {
    setSkip(skip + take);
  };

  const loadData = (skip, take) => {
    api
      .getPayments({ skip, take })
      .then((response) => {
        if (response.data.length < take) {
          setDisabledMore(true);
        }
        setPayments([...payments, ...response.data]);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    if (auth.isReady) {
      setLoading(true);
      loadData(skip, take);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (skip > 0) {
      setLoadingMore(true);
      loadData(skip, take);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, take]);

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box mb={2}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link component={RouterLink} to={'/profile'} color="inherit" style={{ fontSize: 20 }}>
            {t('translation:profile:title')}
          </Link>

          <Typography color="textPrimary" component="div">
            <Box fontSize={20} fontWeight={600}>
              {t('translation:paymentsHistory:title')}
            </Box>
          </Typography>
        </Breadcrumbs>
      </Box>

      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Box p={5}>
                <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                  <Box>{''}</Box>

                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component={RouterLink}
                      to={ROUTES.USER_PROFILE_TOP_UP_BALANCE}
                    >
                      {t('translation:profile:topUp')}
                    </Button>
                  </Box>
                </Box>

                <Box mt={4}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>{t('translation:paymentsHistory:paymentMethod')}</StyledTableCell>
                          <StyledTableCell>{t('translation:paymentsHistory:date')}</StyledTableCell>
                          <StyledTableCell>{t('translation:paymentsHistory:amount')}</StyledTableCell>
                          <StyledTableCell>{t('translation:paymentsHistory:actions')}</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payments.map((item, index) => (
                          <TableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              {item.currency === 'ETH' ? 'Ethereum' : item.currency === 'BTC' ? 'Bitcoin' : 'Bank card'}
                            </StyledTableCell>
                            <StyledTableCell>{moment.unix(item.ptime).format('MMM DD, yyyy')}</StyledTableCell>
                            <StyledTableCell>{item.amount + ' USD'}</StyledTableCell>
                            <StyledTableCell>
                              <IconButton style={{ color: '#2E3542' }}>
                                <VisibilityOutlinedIcon />
                              </IconButton>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box display="flex" justifyContent="center" mt={5} alignItems="center">
                  {isDisabledMore ? null : (
                    <Button
                      color="primary"
                      size="small"
                      onClick={handleClickLoadMore}
                      disabled={isLoadingMore}
                      startIcon={
                        isLoadingMore ? <CircularProgress color="inherit" size={16} /> : <ExpandMoreOutlinedIcon />
                      }
                    >
                      {t('translation:paymentsHistory:loadMore')}
                    </Button>
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default PaymentsHistory;
