import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AuthenticationTypes from '../../components/AuthenticationTypes';
import * as ROUTES from '../../constants/routes';
import { useAuth } from '../../hooks/useAuth';
import { useRouter } from '../../hooks/useRouter';

const defaultTheme = createMuiTheme();
export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(5, 0, 0, 0),
  },
  submit: {
    margin: theme.spacing(3, 0, 5, 0),
  },
  error: {
    color: theme.palette.error.main,
  },
  textField: {
    marginBottom: defaultTheme.spacing(4),
  },
  input: {
    fontSize: defaultTheme.typography.fontSize + 10,
  },
}));

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const SignIn = () => {
  const router = useRouter();
  const classes = useStyles();
  const auth = useAuth();
  const { t } = useTranslation();

  const [state, setState] = useState(INITIAL_STATE);
  const [isLoading, setLoading] = useState(false);
  const [verified] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onSubmit = async (event) => {
    const { email, password } = state;

    setLoading(true);
    auth
      .signIn(email.trim(), password.trim())
      .then((user) => {
        setLoading(false);
        setState({ ...INITIAL_STATE });

        router.history.push(ROUTES.ROOT);
      })
      .catch((error) => {
        setLoading(false);
        setState({ ...state, error });
      });

    event.preventDefault();
  };

  const { email, password, error } = state;
  const isInvalid = password === '' || email === '';

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h4">
        {t('translation:auth:signIn:title')}
      </Typography>

      <AuthenticationTypes />

      <Box display="flex" justifyContent="center" alignItems="center" mb={1} mt={2}>
        <Typography component="div">
          <Box>{t('translation:auth:signIn:noAccount')}</Box>
        </Typography>

        <Box ml={1}>
          <Button component={Link} to={ROUTES.AUTH_SIGN_UP} color="secondary" style={{ fontWeight: 600 }}>
            {t('translation:auth:signIn:signUpLabel')}
          </Button>
        </Box>
      </Box>

      <Container maxWidth="sm">
        <Box textAlign="center" mb={4}>
          {error && (
            <Typography component="p" variant="body1" className={classes.error}>
              {error.message}
            </Typography>
          )}
        </Box>

        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            type="email"
            id="email"
            name="email"
            value={email}
            color="primary"
            onChange={onChange}
            placeholder={t('translation:auth:signIn:inputEmailPlaceholder')}
            required
            autoComplete="false"
            inputProps={{
              autoComplete: 'false',
            }}
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />

          <TextField
            type="text"
            id="password"
            name="password"
            value={password}
            style={{ '-webkit-text-security': showPassword ? 'none' : 'disc' }}
            onChange={onChange}
            autoComplete="false"
            placeholder={t('translation:auth:signIn:inputPasswordPlaceholder')}
            inputProps={{
              autoComplete: 'false',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            required
            fullWidth
          />

          {/* <Box textAlign="right" mb={2}>
            <Button component={Link} size="small" to={ROUTES.AUTH_FORGOT_PASSWORD} style={{ fontWeight: 600 }}>
              {t('translation:auth:signIn:forgotPassword')}
            </Button>
          </Box> */}

          <Box display="flex" justifyContent="center" mb={1} mt={1}>
            {/*<ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KAY} onChange={onRecaptchaChange} />*/}
          </Box>

          <Box display="flex" flexDirection="column">
            <Box textAlign="center">
              <Button
                type="submit"
                color="primary"
                size="large"
                disabled={isInvalid || !verified}
                variant="contained"
                className={classes.submit}
              >
                {!isLoading ? (
                  t('translation:auth:signIn:signInButton')
                ) : (
                  <CircularProgress color="inherit" size={26} />
                )}
              </Button>
            </Box>
          </Box>
        </form>
      </Container>
    </div>
  );
};

export default SignIn;
