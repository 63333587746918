import { Box, ButtonBase, Paper, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import supportIcon1 from '../../assets/support-icon-1.png';
import supportIcon2 from '../../assets/support-icon-2.png';
import supportIcon3 from '../../assets/support-icon-3.png';
import supportIcon4 from '../../assets/support-icon-4.png';
import supportImg from '../../assets/support-img.png';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../hooks/useRouter';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
  paper: {
    marginBottom: theme.spacing(2),
    minWidth: '100%',

    '&:hover': {
      boxShadow: theme.shadows[3],
      cursor: 'pointer',
    },
  },
}));

const Support = () => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box display="flex" justifyContent="center" mb={5}>
        <Box>
          <Box textAlign="center">
            <img src={supportImg} alt={supportImg} width={153} height={153} />
          </Box>

          <Typography component="div">
            <Box fontSize={26} fontWeight={600} textAlign="center">
              {t('translation:support:title')}
            </Box>
          </Typography>

          <Typography component="div">
            <Box fontSize={16} textAlign="center">
              {t('translation:support:description')}
            </Box>
          </Typography>
        </Box>
      </Box>

      <Box>
        <Paper
          elevation={1}
          className={classes.paper}
          component={ButtonBase}
          onClick={() => window.tidioChatApi.open()}
        >
          <Box display="flex" p={3} alignItems="center">
            <Box mr={4} ml={1}>
              <img src={supportIcon1} alt={supportIcon1} />
            </Box>

            <Box textAlign="left">
              <Typography component="div">
                <Box fontSize={20} fontWeight={600} mb={1}>
                  {t('translation:support:quickSupport:title')}
                </Box>
              </Typography>

              <Typography component="div">
                <Box fontSize={16} lineHeight={1.3} style={{ color: '#627087' }}>
                  {t('translation:support:quickSupport:description')}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Paper>

        <a href="mailto:support@savetofuture.com" style={{ textDecoration: 'none' }}>
          <Paper elevation={1} className={classes.paper}>
            <Box display="flex" p={3} alignItems="center">
              <Box mr={4} ml={1}>
                <img src={supportIcon2} alt={supportIcon2} />
              </Box>

              <Box textAlign="left">
                <Typography component="div">
                  <Box fontSize={20} fontWeight={600} mb={1}>
                    {t('translation:support:requestSupport:title')}
                  </Box>
                </Typography>

                <Typography component="div">
                  <Box fontSize={16} lineHeight={1.3} style={{ color: '#627087' }}>
                    {t('translation:support:requestSupport:description')}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </a>

        <Paper
          elevation={1}
          className={classes.paper}
          component={ButtonBase}
          onClick={() => {
            router.history.push(ROUTES.USER_FAQ);
          }}
        >
          <Box display="flex" p={3} alignItems="center">
            <Box mr={4} ml={1}>
              <img src={supportIcon3} alt={supportIcon3} />
            </Box>

            <Box textAlign="left">
              <Typography component="div">
                <Box fontSize={20} fontWeight={600} mb={1}>
                  {t('translation:support:faq:title')}
                </Box>
              </Typography>

              <Typography component="div">
                <Box fontSize={16} lineHeight={1.3} style={{ color: '#627087' }}>
                  {t('translation:support:faq:description')}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Paper
          elevation={1}
          className={classes.paper}
          component={ButtonBase}
          onClick={() => {
            router.history.push(ROUTES.USER_PRICING);
          }}
        >
          <Box display="flex" p={3} alignItems="center">
            <Box mr={4} ml={1}>
              <img src={supportIcon4} alt={supportIcon4} />
            </Box>

            <Box textAlign="left">
              <Typography component="div">
                <Box fontSize={20} fontWeight={600} mb={1}>
                  {t('translation:support:pricing:title')}
                </Box>
              </Typography>

              <Typography component="div">
                <Box fontSize={16} lineHeight={1.3} mb={1} style={{ color: '#627087' }}>
                  {t('translation:support:pricing:description')}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Support;
