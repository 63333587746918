import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import logoDashboard from '../../assets/logo-dashboard.png';

const useStyles = makeStyles(() => ({
  message: {
    color: '#FFFFFF',
  },
}));

const ProcessingStatusWidget = (props) => {
  const classes = useStyles();
  const { messageBackground, messageText } = props;

  return (
    <Paper elevation={1} className={classes.message} style={{ backgroundColor: messageBackground }}>
      <Box display="flex" alignItems="center" p={3}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <Box>
              <Typography component="div">
                <Box display="flex" direction="row" alignItems="center" justifyContent="center" fontSize={26} lineHeight={1.3}>
                  {messageText}
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ProcessingStatusWidget;
