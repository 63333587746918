import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';

const styles = buildStyles({
  textColor: '#2E3542',
  pathColor: '#80D948',
});

const ServiceUptimeWidget = (props) => {
  const { serviceUptime } = props;
  const { t } = useTranslation();

  return serviceUptime ? (
    <Paper elevation={1} style={{ height: '100%', display: 'flex' }}>
      <Box p={4} display="flex" alignItems="center">
        <Box mr={5}>
          <Box style={{ width: 75, height: 75 }}>
            <CircularProgressbar value={serviceUptime} text={serviceUptime + '%'} styles={styles} />
          </Box>
        </Box>

        <Box>
          <Typography component="div">
            <Box fontSize={20} lineHeight={1.1}>
              {t('translation:dashboard:status:uptimeWidget:uptime')}
            </Box>
          </Typography>
          <Typography component="div" style={{ color: '#A6AAB0' }}>
            <Box fontSize={14} lineHeight={1}>
              {t('translation:dashboard:status:uptimeWidget:label')}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Paper>
  ) : null;
};

export default ServiceUptimeWidget;
